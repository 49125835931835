import i18next from 'i18next';
import {initReactI18next} from "react-i18next";
import translation from "./Locales/pl/translation";

const resources = {
    pl: {
        translation: translation
    }
};

i18next
    .use(initReactI18next)
    .init({
        resources,
        lng: "pl-PL",
        initImmediate : false,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18next;
