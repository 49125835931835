import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import waitForCsrfToken from '@/Functions/waitForCsrfToken';

export const culinaryApi = createApi({
  reducerPath: 'culinaryApi',
  tagTypes: ['CulinarySet', 'DetailsCulinarySet'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v2/',
    prepareHeaders: async (headers, { getState }) => {
      const csrfToken = await waitForCsrfToken(getState);
      if (csrfToken) {
        headers.set('X-CSRF-TOKEN', csrfToken);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getCulinarySetWithQuery: builder.query({
      query: (params) => ({
        url: route('api.v2.getCulinarySetWithQuery'),
        method: 'POST',
        params: params
      }),
      providesTags: ['CulinarySet'],
      keepUnusedDataFor: 300
    }),
    loadIngredients: builder.query({
      query: (culinarySet) => ({
        url: route('api.v2.loadIngredientsCulinarySet', culinarySet),
        method: 'POST'
      }),
      providesTags: ['DetailsCulinarySet'],
      keepUnusedDataFor: 300
    })
  })
});

export const { useGetCulinarySetWithQueryQuery, useLoadIngredientsQuery } =
  culinaryApi;
