import React, { createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';
import {
  useFetchUserSettingsQuery,
  useUpdateUserSettingsMutation,
  useAddOrUpdateSettingMutation,
} from '@/services/userSettingsApi';

const UserSettingsContext = createContext({
  settings: [],
  loading: true,
  error: null,
  getSetting: (key: string) => null,
  setNewSetting: (key: string, value: string) => {},
});

export const useUserSettings = () => {
  return useContext(UserSettingsContext);
};

export const UserSettingsProvider = ({ children }) => {
  const dispatch = useDispatch();

  const { data: settings = [], error, isLoading } = useFetchUserSettingsQuery();
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const [addOrUpdateSetting] = useAddOrUpdateSettingMutation();

  const sendUserSettings = async (key, value) => {
    await updateUserSettings({ key, value });
  };

  const getSetting = (key) => {
    return settings?.find((option) => option.key === key) || null;
  };

  const setNewSetting = async (key, value) => {
      await sendUserSettings(key, value);
      await addOrUpdateSetting({ key, value });
  };

  const value = {
    settings,
    loading: isLoading,
    error,
    getSetting,
    setNewSetting,
  };

  return (
    <UserSettingsContext.Provider value={value}>
      {children}
    </UserSettingsContext.Provider>
  );
};
