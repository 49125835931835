import { SET_DISH_IN_CACHE, CLEAR_DISH_ON_CACHE } from '@/redux/actions/dishSaveInCacheActions';
import { DishInterface } from '@Interfaces/dishInterface';

export interface DishIngredientsState {
  dish: DishInterface
}

const initialState: DishIngredientsState = {
  dish: {}
};

const dishInCacheReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DISH_IN_CACHE:
      return {
        ...state,
        dish: action.payload
      };
    case CLEAR_DISH_ON_CACHE:
      return {
        ...state,
        dish: []
      };
    default:
      return state;
  }
};

export default dishInCacheReducer;
