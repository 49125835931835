import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import waitForCsrfToken from '@/Functions/waitForCsrfToken';

export const productApi = createApi({
  reducerPath: 'productApi',
  tagTypes: ['Products'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v2/',
    prepareHeaders: async (headers, { getState }) => {
      const csrfToken = await waitForCsrfToken(getState);
      if (csrfToken) {
        headers.set('X-CSRF-TOKEN', csrfToken);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getProduct: builder.query({
      query: (params) => ({
        url: route('products.query'),
        method: 'GET',
        params: {
          'page[number]': params.page,
          'page[size]': params.size,
          'filter[name]':
            (params?.filters.name ?? '') !== ''
              ? params?.filters.name
              : undefined,
        }
      }),
      providesTags: ['Products'],
      keepUnusedDataFor: 300
    })
  })
});

export const { useGetProductQuery } = productApi;
