export const SET_USER_FILTER_RETURN = 'SET_USER_FILTER_RETURN';
export const RESET_USER_FILTER_RETURN = 'RESET_USER_FILTER_RETURN';

export const setUserFilterReturn = (userFilterReturn) => ({
  type: SET_USER_FILTER_RETURN,
  payload: userFilterReturn,
});

export const resetUserFilterReturn = () => ({
  type: RESET_USER_FILTER_RETURN,
});