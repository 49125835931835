import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import waitForCsrfToken from '@/Functions/waitForCsrfToken';
import { data } from 'autoprefixer';

export const userSettingsApi = createApi({
  reducerPath: 'userSettingsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: async (headers, { getState }) => {
      const csrfToken = await waitForCsrfToken(getState);
      if (csrfToken) {
        headers.set('X-CSRF-TOKEN', csrfToken);
      }
      return headers;
    }
  }),
  tagTypes: ['UserSettings'],
  endpoints: (builder) => ({
    fetchUserSettings: builder.query({
      query: () => 'user-settings',
      providesTags: ['UserSettings'],
    }),
    updateUserSettings: builder.mutation({
      query: (settings) => ({
        url: 'user-settings',
        method: 'PUT',
        body: settings,
      }),
      invalidatesTags: ['UserSettings'],
    }),
    addOrUpdateSetting: builder.mutation({
      queryFn: async (newSetting, { dispatch, getState }) => {
        const state = getState();
        const existingSettings = state.userSettingsApi.queries['fetchUserSettings']?.data || [];
        const updatedSettings = existingSettings.some(setting => setting.key === newSetting.key)
          ? existingSettings.map(setting =>
            setting.key === newSetting.key ? { ...setting, value: newSetting.value } : setting
          )
          : [...existingSettings, newSetting];

        return { data: updatedSettings };
      },
      invalidatesTags: ['UserSettings'],
    }),
  }),
});

export const {
  useFetchUserSettingsQuery,
  useUpdateUserSettingsMutation,
  useAddOrUpdateSettingMutation,
} = userSettingsApi;
