import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import rootReducer, { RootState } from '@/redux/slices';


import { culinaryApi } from '@/redux/slices/culinaryApi';
import { productApi } from '@/redux/slices/productApi';
import { productAndDishApi } from '@/redux/slices/productAndDishApi';
import { userSettingsApi } from '@/services/userSettingsApi';
import { usersApi } from '@/services/usersApi';

const index = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(culinaryApi.middleware)
      .concat(productApi.middleware)
      .concat(productAndDishApi.middleware)
      .concat(userSettingsApi.middleware)
      .concat(usersApi.middleware),
});

export type AppDispatch = typeof index.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default index;
