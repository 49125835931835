export default async function  waitForCsrfToken(getState)  {
  let csrfToken = getState().csrfToken;
  let attempts = 0;
  const maxAttempts = 3;
  const delay = 50;

  while (!csrfToken && attempts < maxAttempts) {
    await new Promise(resolve => setTimeout(resolve, delay));
    csrfToken = getState().csrfToken;
    attempts += 1;
  }

  return csrfToken;
};