import { SET_USER_FILTER_RETURN, RESET_USER_FILTER_RETURN } from '@/redux/actions/userFilterActions';

const initialState = {
  userFilterReturn: {
    type: null,
    id: null,
    name: null,
    subAccounts: []
  },
};

const userFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_FILTER_RETURN:
      return {
        ...state,
        userFilterReturn: action.payload,
      };
    case RESET_USER_FILTER_RETURN:
      return {
        ...state,
        userFilterReturn: initialState.userFilterReturn,
      };
    default:
      return state;
  }
};

export default userFilterReducer;
