import 'animate.css/animate.min.css';
import 'croppie/croppie.css';
import 'react-toastify/dist/ReactToastify.css';
import '../css/app.scss';

import React, { useEffect } from 'react';
import Modal from 'react-modal';
import './i18n';
import { InertiaProgress } from '@inertiajs/progress';
import { createInertiaApp } from '@inertiajs/inertia-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactNotifications } from 'react-notifications-component';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { render } from 'react-dom';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import store from '@/redux/store';
import { CsrfTokenProvider } from '@/Contexts/CsrfTokenContext';
import { UserSettingsProvider } from '@/Contexts/UserSettingsContext';

const app = document.getElementById('app');

Modal.setAppElement(app);

InertiaProgress.init({
  color: '#008479',
  showSpinner: true
});

createInertiaApp({
  resolve: name => resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
  setup({ el, App, props }) {
    const queryClient = new QueryClient();

    const MainApp = (appProps) => {
      return (
        <Provider store={store}>
          <CsrfTokenProvider>
            <QueryClientProvider client={queryClient}>
              <UserSettingsProvider>
                <ToastContainer
                  position={'top-right'}
                  autoClose={2000}
                  theme="light"
                />
                <ReactNotifications />
                <App {...appProps} />
              </UserSettingsProvider>
            </QueryClientProvider>
          </CsrfTokenProvider>
        </Provider>
      );
    };

    render(<MainApp {...props} />, el);
  }
});
