import { SHOW_DISABLE_APP_MODAL, HIDE_DISABLE_APP_MODAL } from '../actions/disabledAppModalActions';

const initialState = {
  isOpen: true,
}

const disabledAppModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_DISABLE_APP_MODAL:
      return { ...state, isOpen: true };
    case HIDE_DISABLE_APP_MODAL:
      return { ...state, isOpen: false };
    default:
      return state;
  }
};

export default disabledAppModalReducer;
