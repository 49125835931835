import React, { createContext, useContext } from 'react';

const CsrfTokenContext = createContext(null);

export const useCsrfToken = () => useContext(CsrfTokenContext);

export const CsrfTokenProvider = ({ children, csrfToken }) => (
  <CsrfTokenContext.Provider value={csrfToken}>
    {children}
  </CsrfTokenContext.Provider>
);
