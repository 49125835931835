import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import waitForCsrfToken from '@/Functions/waitForCsrfToken';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  tagTypes: ['Users'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v2/',
    prepareHeaders: async (headers, { getState }) => {
      const csrfToken = await waitForCsrfToken(getState);
      if (csrfToken) {
        headers.set('X-CSRF-TOKEN', csrfToken);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    fetchUsers: builder.query({
      query: (params) => ({
        url: route('api.v2.customers.index'),
        method: 'GET',
        params: params
      }),
      providesTags: ['Users'],
      keepUnusedDataFor: 300
    }),
  }),
});

export const {
  useFetchUsersQuery,
} = usersApi;
