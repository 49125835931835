import { DishInterface } from '@Interfaces/dishInterface';

export const SET_DISH_IN_CACHE = 'SET_DISH_IN_CACHE';
export const CLEAR_DISH_ON_CACHE = 'CLEAR_DISH_ON_CACHE';

export const setDishInCache= (dish: DishInterface) => ({
  type: SET_DISH_IN_CACHE,
  payload: dish
});

export const clearDishOnCache = () => ({
  type: CLEAR_DISH_ON_CACHE
});